import React, { useContext } from "react"
import Template from "../components/template"
import ContactForm from "../components/contact-form"
import "../styles/hilfe.css"
import { hilfeContent } from "../content/hilfe"
import { SettingsContext } from "../contexts/settings"
import { graphql } from "gatsby"
import { translate } from "../../utils/language" 
import {BlogpostDownload} from "../components/BlogpostDownload"
import {BlogpostBildMitText} from "../components/BlogpostBildMitText"
import {BlogpostText} from "../components/BlogpostText"
import {BlogpostGallerie} from "../components/BlogpostGallerie"
import {BlogpostVideo} from "../components/BlogpostVideo"
import {BlogpostExternerInhalt} from "../components/BlogpostExternerInhalt"
import {BlogpostExternerInhaltMitText} from "../components/BlogpostExternerInhaltMitText"
import { renderMarkdown } from "../../utils/rendermarkdown"


const HilfePage = ({data}) => {
    const [settings, ] = useContext(SettingsContext)
    const content = hilfeContent[settings.language]
    const l = settings.language
    const b = data.strapi.awareness
    return (
        <Template currentPage="help">
            <section className="end-of-site">
                <div>
                <h1>{translate(l, b.Titel_de, b.Titel_en)}</h1>
                <div className="awareness-content">
                    <div className="awareness-texte">
                        {
                            b.Inhalt.map(blogComponent => {
                                switch (blogComponent.__typename) {
                                    case "Strapi_ComponentDefaultMehrsprachigerDownload":
                                        return <BlogpostDownload data={blogComponent} language={l} />;
                                    case "Strapi_ComponentDefaultBildMitMehrsprachigemText":
                                        return <BlogpostBildMitText data={blogComponent} language={l} />;
                                    case "Strapi_ComponentDefaultMehrsprachigerText":
                                        return <BlogpostText data={blogComponent} language={l} />;
                                    case "Strapi_ComponentDefaultGallerie":
                                        return <BlogpostGallerie data={blogComponent} language={l} />;
                                    case "Strapi_ComponentDefaultVideo":
                                        return <BlogpostVideo data={blogComponent} />;
                                    case "Strapi_ComponentDefaultExternerInhalt":
                                        return <BlogpostExternerInhalt data={blogComponent} />;
                                    case "Strapi_ComponentDefaultExternerInhaltMitText":
                                        return <BlogpostExternerInhaltMitText data={blogComponent} language={l} />;
                                    case "Strapi_ComponentDefaultTrennstrich":
                                        return <div className="mint-line-blogpost" data={blogComponent}/>;
                                }
                            }


                            )
                        }
                    </div>
                    <div className="awareness-infoboxen">
                        { b.Infoboxen.map(box => {	
                            return <div>
                                {
                                    
                                    <div className="good-to-know-container">
                                        <div><h2 className="box-heading">{translate(l, box.Titel_deutsch, box.Titel_englisch)}</h2></div>
                                        <p>{renderMarkdown(translate(l, box.Inhalt_deutsch, box.Inhalt_englisch))}</p>
                                    </div>
                                }

                            </div>
                        })}
                    </div>
                </div>    
                    
                    <ContactForm apiSuffix="hilfe" mailto="mailto:aware@nocturnal-unrest.de" email="aware@nocturnal-unrest.de" titel={content.titelContactForm}></ContactForm>
                </div>
            </section>
        </Template>
    )
}

export default HilfePage

export const query = graphql`
  query MyQuery4 {
      strapi {
        awareness {
              Titel_de
              Titel_en
              Infoboxen {
                Inhalt_deutsch
                Inhalt_englisch
                Titel_deutsch
                Titel_englisch
                id
              }
              Inhalt {
                  __typename  
              ... on Strapi_ComponentDefaultMehrsprachigerDownload {
                  Titel_englisch
                  Titel_deutsch
                  Link_deutsch
                  Link_englisch
                  Download_deutsch {
                  url
                  }
                  Download_englisch {
                  url
                  }
              }
              ... on Strapi_ComponentDefaultBildMitMehrsprachigemText {
                  Deutsch
                  Englisch
                  Titel_deutsch
                  Titel_englisch
                  bild {
                        Deutsch
                        Englisch
                        Untertitel_de
                        Untertitel_en
                        Bild {
                            url
                        }
                  }
              }
              ... on Strapi_ComponentDefaultVideo {
                  VideoDatei {
                  url
                  }
              }
              ... on Strapi_ComponentDefaultMehrsprachigerText {
                  Deutsch
                  Englisch
              }
              ... on Strapi_ComponentDefaultGallerie {
                  Bilder {
                  Bild {
                      url
                  }
                  Deutsch
                  Englisch
                  Untertitel_de
                    Untertitel_en
                  }
              }
              ... on Strapi_ComponentDefaultExternerInhalt {
                  URL
              }
              ... on Strapi_ComponentDefaultTrennstrich {
                  id
              }
              ... on Strapi_ComponentDefaultExternerInhaltMitText {
                  Deutsch
                  Englisch
                  URL
              }
          }
          }
      }
  }
`